<template>
  <Transition name="fade" mode="out-in">
    <slot />
  </Transition>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.fade-enter-active,
.fade-leave-active {
  transition: $base-transition opacity;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
